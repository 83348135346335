const blockHeader = async($block) => {
	const MobileMenuModule = await import( '../classes/mobile-menu' );
	const MobileMenu = MobileMenuModule.default;
	const { gsap } = await import( 'gsap' );
	const { ScrollTrigger } = await import( 'gsap/ScrollTrigger' );
	gsap.registerPlugin( ScrollTrigger );

	const $header = $block;

	function isBlockEditor() {
		return document.body.classList.contains('block-editor-page') ||
			document.body.classList.contains('block-editor-iframe__body');
	}

	const isHeaderInitiallyFixedFrontend = () => {
		let isHeaderInitiallyFixed = true;
		const nextSectionsInitiallyNotFixed = ['section-hero'];

		let $nextSection = $header.next('.section');
		if (!$nextSection.length) {
			$nextSection = $header.closest('.wp-block-template-part').next('.section');
		}

		const nextSectionClasses = $nextSection.attr('class').split(' ');
		for (let i = 0; i < nextSectionClasses.length; i++) {
			if (nextSectionsInitiallyNotFixed.includes(nextSectionClasses[i])) {
				isHeaderInitiallyFixed = false;
				break;
			}
		}

		return isHeaderInitiallyFixed;
	};

	const initHeaderSiteEditor = () => {
		new MobileMenu();
	};

	const initHeaderFrontend = () => {
		if ( isHeaderInitiallyFixedFrontend() ) {
			$header.addClass('page-header--fixed page-header--initially-fixed');
			if ($header.parent('.wp-block-template-part')) {
				$header.parent('.wp-block-template-part').addClass('wp-block-template-part--header-initially-fixed');
			}
		} else {
			ScrollTrigger.create( {
				scroller: '.wp-site-blocks',
				start: 'top+=80',
				endTrigger: '.page-footer',
				end: 'bottom top',
				toggleClass: {targets: '.page-header', className: 'page-header--fixed'},
				markers: false,
			} );
		}

		new MobileMenu();
	};


	if (isBlockEditor()) {
		initHeaderSiteEditor();
	} else {
		initHeaderFrontend();
	}
};

themeUtils.loadBlock( blockHeader, 'header', '.page-header' );
